import React from "react"
import { graphql, Link } from "gatsby"

import Layout from '../components/layout'

import Breadcrumb from "../components/Global/Breadcrumb"

import { GatsbyImage } from "gatsby-plugin-image";

import logoWhite from "../images/logo-karanikolas-white.png";



function SmartGreenTemplate(props) {
    let data = props.data.wpgraphql;

    function getBreadcrumb() {
        let bCrumb = [];
        bCrumb.push({
            text: "Home",
            url: `${props.pageContext.language !== "EL" ? "/" + props.pageContext.language.toLowerCase() : "/"}`
        })
        bCrumb.push({
            text: data.page.breadcrumbAcf.breadcrumbName,
        })
        return bCrumb;
    }

    function constructMetaData(page, currentPage, language) {
        let payload = {
            title: page.seo.title,
            description: page.seo.metaDesc,
            canonical: `https://karanikolascon.gr${currentPage}`,
            fbTitle: page.seo.opengraphTitle ? page.seo.opengraphTitle : page.seo.title,
            fbDescription: page.seo.opengraphDescription ? page.seo.opengraphDescription : page.seo.metaDesc,
            fbUrl: `https://karanikolascon.gr${currentPage}`,
            fbType: "website",
            locale: language.toLowerCase(),
            image: page.seo.opengraphImage ? page.seo.opengraphImage.sourceUrl : ""
        }
        return payload
    }

    return (
        <div>
            <Layout
                headerMenu={props.pageContext.headerMenu}
                footerMenu={props.pageContext.footerMenu}

                currentPage={{ currentPage: props.pageContext.currentPage, language: props.pageContext.languageName }}
                availableVersions={props.pageContext.availablePages}
                metaData={constructMetaData(data.page, props.pageContext.currentPage, props.pageContext.language)}
                services={props.pageContext.servicesCategories}
                projects={props.pageContext.projectsCategories}
                preHeader={data.template.contactInformationTemplate}
            >
                <div>
                    {/* <section>
                        <Introduction backImg={data.page.aboutPageAcf.introductionBackgroundImage} title={data.page.aboutPageAcf.introductionHeader} />
                    </section> */}
                    {data.page.breadcrumbAcf.breadcrumbName && (
                        <section>
                            <Breadcrumb list={getBreadcrumb()} image={data.page.breadcrumbAcf.image} h1={data.page.breadcrumbAcf.breadcrumbName} />
                        </section>

                    )}

                    <section>
                        <div className="m-auto">
                            {data?.page?.aboutPageAcf?.groups && data.page.aboutPageAcf.groups.map((dt, i) => (
                                // <div className="flex flex-wrap items-center py-12" key={`ab-key-${i}`}>
                                //     <div className={`w-full md:w-1/2 pb-4 md:pb-0 ${i % 2 === 0 ? "order-1 md:order-1" : "order-1 md:order-2"}`}>
                                //         {dt?.image?.imageFile?.childImageSharp?.gatsbyImageData && (
                                //             <GatsbyImage
                                //                 image={dt.image.imageFile.childImageSharp.gatsbyImageData}
                                //                 alt={dt.image.altText}
                                //                 className="m-auto" />
                                //         )}

                                //     </div>
                                //     <div className={`w-full md:w-1/2 ${i % 2 === 0 ? "order-2 md:order-2 pl-0 md:pl-8" : "order-2 md:order-1 pr-0 md:pr-8"}`}>
                                //         <div>
                                //             <h2>{dt.title}</h2>
                                //             <div className="pt-4 about-us-editors" dangerouslySetInnerHTML={{ __html: dt.content }} />
                                //         </div>
                                //     </div>
                                // </div>
                                <div key={`ab-key-${i}`}>
                                    <div className="relative" style={{ display: "grid" }}>
                                        <GatsbyImage
                                            style={{
                                                gridArea: "1/1",
                                                // You can set a maximum height for the image, if you wish.
                                                minHeight: "70vh"
                                            }}
                                            alt="altImg"
                                            loading={"eager"}
                                            image={dt.image.imageFile.childImageSharp.gatsbyImageData}
                                            formats={["auto", "webp", "avif"]}
                                        />
                                        <div
                                            style={{
                                                gridArea: "1/1",
                                                position: "relative",
                                                placeItems: "center",
                                                display: "flex",
                                                flexWrap: "wrap"
                                            }}
                                            className="p-8"
                                        >
                                            <div className={`w-full md:w-1/2 ${i % 2 === 0 ? "order-2 md:order-2 pl-0 md:pl-8" : "order-2 md:order-1 pr-0 md:pr-8"}`}>
                                                <div>
                                                    <div className="sm-gr-editors" dangerouslySetInnerHTML={{ __html: dt.content }} />
                                                </div>
                                            </div>
                                            <div className={`w-full md:w-1/2 flex-wrap pb-8 md:pb-0 flex justify-center ${i % 2 === 0 ? "order-1 md:order-1" : "order-1 md:order-2"}`}>
                                                <h2 className="sm-gr-title">{dt.title}</h2>
                                            </div>
                                        </div>
                                        <div className={`w-full absolute sm-logo-all hidden md:block `}>
                                            <img className={`w-64  ${i % 2 !== 0 ? "sm-logo-left" : "sm-logo-right"}`} src={logoWhite} alt="white-logo" />
                                        </div>
                                    </div>
                                </div>
                            ))}

                        </div>
                    </section>

                </div>
            </Layout>
        </div>
    );
}

export default SmartGreenTemplate

export const pageQuery = graphql`query GET_SMART_GREEN($id: ID!, $themeTemplateUri: ID!) {
  wpgraphql {
    page(id: $id) {
      seo {
        canonical
        title
        metaDesc
        opengraphDescription
        opengraphTitle
        opengraphImage {
          sourceUrl
        }
      }
      breadcrumbAcf {
        breadcrumbName
        image {
          sourceUrl
          altText
          imageFile {
            childImageSharp {
              gatsbyImageData(placeholder: BLURRED, layout: FULL_WIDTH)
            }
          }
        }
      }

      aboutPageAcf{
        groups{
            title
            content
            image{
                altText
                sourceUrl
                imageFile {
                    childImageSharp {
                    gatsbyImageData(quality: 100, layout: CONSTRAINED, width:2600, placeholder: BLURRED)
                    }
                }
            }
        }
      }
    }
    
    template(id: $themeTemplateUri, idType: SLUG) {
      contactInformationTemplate {
        telephoneNumber
        mobileNumber
        supportEmail
        hoursLabel
        hoursTitle
        form {
          title
          fullName
          email {
            label
            errorMessage
          }
          subject
          message {
            label
            errorMessage
          }
          telephone {
            label
            errorMessage
          }
          sendButton
        }
        responseMessages {
          success
          failed
        }
      }
    }
    
  }
}
`
